import {
  Button,
  rem,
  createStyles,
  Box,
  BackgroundImage,
  Flex,
  Image,
  Text,
} from "@mantine/core";
import { ChevronsIconRight, LocationIcon } from "assets/icon";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useGetValidateWebLinkQuery } from "store/services/ticketServices";
import LandingBg from "assets/imgs/LandingBg.png";
import { LanguageSelector, Loader } from "components";
import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    textAlign: "center",
    backgroundColor: "#f5f5f5",
    overflow: "clip", // Prevents extra scroll but allows natural flow
    minHeight: "100vh",
  },
  languageSelector: {
    alignSelf: "flex-end",
    margin: rem(15),
    zIndex: 7,
  },
  mainContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    gap: rem(10),
  },
  backgroundImage: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: rem(320),
    padding: `${rem(40)} 0`, // Ensures proper spacing
    backgroundSize: "cover",
    backgroundPosition: "top",
    width: "100%",
  },
  button: {
    fontSize: rem(18),
    width: rem(300),
    borderRadius: rem(8),
    height: rem(50),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  footer: {
    fontSize: rem(12),
    fontWeight: 500,
    marginTop: rem(10),
    textAlign: "center",
  },
  poweredByLink: {
    color: "#F64E60",
  },
}));

const LandingPage = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  useEffect(() => {
    const updateHeight = () => {
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener("resize", updateHeight);
    updateHeight(); // Set initial height

    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  const storage = id;
  window.localStorage.setItem("ticksID", id);

  const { data, isLoading } = useGetValidateWebLinkQuery(storage);
  const apiData = data?.data?.data?.businessDetail?.myBusinessProfile;

  const handleNavigate = () => {
    setLoading(true);
    setTimeout(() => {
      navigate(`/service/${storage}`);
    }, 3000);
  };

  return (
    <Flex direction="column" className={classes.container} h={viewportHeight}>
      {/* Language Selector */}
      <Box className={classes.languageSelector}>
        <LanguageSelector />
      </Box>

      {/* Main Content */}
      <Flex
        direction="column"
        align="center"
        justify="center"
        className={classes.mainContent}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Image
              src={apiData?.logo}
              alt="Service Preview"
              height={rem(153)}
              width={rem(153)}
              radius="100%"
              fit="initial"
            />
            <Text fz="lg" fw="bold">
              {apiData?.businessName}
            </Text>
            <Flex direction="row" gap="xs" justify="center" align="center">
              <LocationIcon h={15} w={15} color="#F64E60" />
              <Text color="dimmed" fz="xs">
                {apiData?.address?.address}
              </Text>
            </Flex>
          </>
        )}
      </Flex>

      {/* Bottom Section with Background Image */}
      <BackgroundImage src={LandingBg} className={classes.backgroundImage}>
        {/* Button inside the wave section */}
        {loading ? (
          <Loader />
        ) : (
          <Button
            onClick={handleNavigate}
            variant="filled"
            color="dark"
            className={classes.button}
          >
            {t("book-your-appointment")}
            <ChevronsIconRight w={22} h={22} />
          </Button>
        )}

        {/* Footer inside the wave section */}
        <Text className={classes.footer}>
          Powered by
          <Text
            component="a"
            href="https://bookzyme.com"
            rel="noreferrer"
            target="_blank"
            className={classes.poweredByLink}
          >
            {" "}
            BookzyMe
          </Text>
        </Text>
      </BackgroundImage>
    </Flex>
  );
};

export default LandingPage;
