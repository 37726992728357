import React, { useState, useEffect, useMemo } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import { Flex, Button, Text, Divider, rem } from "@mantine/core";
import { useAtom } from "jotai";

import { BOOK_SERVICE_BODY, STRIPE_AC_ID, BOOKING_ID } from "constants";
import { step as eventStep, serviceStep } from "store/atoms";

import EventInfoCard from "views/TicketBooking/EventInfoCard";
import EventBuyingSummary from "views/TicketBooking/EventBuyingSummary";
import ServiceInfoCard from "views/TicketServices/ServiceInfoCard";
import ServiceBuyingSummary from "views/TicketServices/ServiceBuyingSummary";
import { ErrorAlert, Loader, UserDetails } from "components";

import GoogleIcon from "assets/icon/Google.png";
import AppleIcon from "assets/icon/Apple.png";
import { ChevronsIconLeft } from "assets/icon";
import { useCreateStripePaymentIntentMutation } from "store/services/ticketServices";
import { useTranslation } from "react-i18next";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// const stripePromise = loadStripe(
//   process.env.REACT_APP_ENVIRONMENT === "PROD"
//     ? process.env.REACT_APP_STRIPE_LIVE_KEY
//     : process.env.REACT_APP_STRIPE_TEST_KEY
//   // { stripeAccount: stripeAcId }
// );
export default function SocialPayment() {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { type } = location.state || {};
  const reduxstate = useSelector((state) => state.State);
  const stripeAcId = window.localStorage.getItem(STRIPE_AC_ID);
  let bookServiceBody = window.localStorage.getItem(BOOK_SERVICE_BODY) || {};
  bookServiceBody = Object.keys(bookServiceBody).length
    ? JSON.parse(bookServiceBody)
    : {};
  const [, setEventStep] = useAtom(eventStep);
  const [, setServiceStep] = useAtom(serviceStep);

  const [stripe, setStripe] = useState(null);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [canMakePayment, setCanMakePayment] = useState({});

  const [createPaymentIntent] = useCreateStripePaymentIntentMutation();

  async function getClientSecret() {
    const body = {
      serviceId: bookServiceBody.service_id,
      slotDuration: bookServiceBody.duration,
      priceType: bookServiceBody.priceType,
      slotPrice: Number(reduxstate.slotData?.price),
      slotDescription:
        reduxstate.slotData?.description || reduxstate.serviceData.serviceName,
    };

    const serviceBookingPayload = {
      ...bookServiceBody,
      bookingId: "",
      utcOffset: new Date().getTimezoneOffset(),
    };

    const paymentMethod = type === "google" ? "googlePay" : "applePay";

    const response = await createPaymentIntent({
      amount: Number(reduxstate.totalPrice),
      paymentMethod,
      currency: "eur",
      isPackage: false,
      serviceBookingPayload,
      ...body,
    }).unwrap();

    return response?.data;
  }

  useEffect(() => {
    loadStripe(
      process.env.REACT_APP_ENVIRONMENT === "PROD"
        ? process.env.REACT_APP_STRIPE_LIVE_KEY
        : process.env.REACT_APP_STRIPE_TEST_KEY,
      stripeAcId ? { stripeAccount: stripeAcId } : undefined
    ).then(async (stripeRes) => {
      setStripe(stripeRes);
      const { client_secret, referenceId, amountInCents } =
        await getClientSecret();
      window.localStorage.setItem(BOOKING_ID, referenceId);
      const pr = stripeRes.paymentRequest({
        country: "NL",
        currency: "eur",
        total: {
          label: "Total",
          amount: amountInCents, // in cents
        },
        requestPayerName: true,
        requestPayerEmail: false,
      });
      pr.canMakePayment().then((result) => {
        console.log(result);
        if (result) {
          setPaymentRequest(pr);
          setCanMakePayment(result);
        } else {
          const typeCapitalized = type.charAt(0).toUpperCase() + type.slice(1);
          confirmAlert({
            customUI: ({ onClose }) => (
              <ErrorAlert
                onClose={() => {
                  onClose();
                  setServiceStep(4);
                  navigate(-1, { replace: true });
                }}
                error={t("errors.payment-unsupported", {
                  type: typeCapitalized,
                })}
                isServerError={false}
              />
            ),
            closeOnEscape: false,
            closeOnClickOutside: false,
          });
        }
      });
      pr.on("paymentmethod", async (ev) => {
        const { error, paymentIntent } = await stripeRes.confirmCardPayment(
          client_secret,
          {
            payment_method: ev.paymentMethod.id,
          },
          { handleActions: false }
        );
        if (error) {
          ev.complete("fail");
          confirmAlert({
            customUI: ({ onClose }) => (
              <ErrorAlert
                onClose={() => {
                  onClose();
                  setEventStep(3);
                  navigate(-1, { replace: true });
                }}
                error={error.message}
                isServerError={false}
              />
            ),
            closeOnEscape: false,
            closeOnClickOutside: false,
          });
          return;
        }
        if (paymentIntent) {
          ev.complete("success");
          if (paymentIntent.status === "requires_action") {
            await stripeRes.confirmCardPayment(client_secret);
          }
          if (paymentIntent.status === "succeeded") {
            const params = new URLSearchParams();
            params.append("payment_intent", paymentIntent.id);

            navigate(`/payment-check?${params.toString()}`, {
              replace: true,
            });
          }
        }
      });
      pr.on("cancel", () => {
        confirmAlert({
          customUI: ({ onClose }) => (
            <ErrorAlert
              onClose={() => {
                onClose();
                navigate(-1, { replace: true });
              }}
              error={t("payment_canceled")}
              isServerError={false}
            />
          ),
          closeOnEscape: false,
          closeOnClickOutside: false,
        });
        return;
      });
    });
  }, [stripeAcId]);

  const handleOpenSheet = () => {
    if (type === "google") {
      if (!canMakePayment.googlePay) {
        confirmAlert({
          customUI: ({ onClose }) => (
            <ErrorAlert
              onClose={() => {
                onClose();
                setServiceStep(4);
                navigate(-1, { replace: true });
              }}
              error={t("errors.payment-card-required", { type: "Google" })}
              isServerError={false}
            />
          ),
          closeOnEscape: false,
          closeOnClickOutside: false,
        });
        return;
      }
      paymentRequest.show();
    } else if (type === "apple") {
      if (!canMakePayment.applePay) {
        confirmAlert({
          customUI: ({ onClose }) => (
            <ErrorAlert
              onClose={() => {
                onClose();
                setServiceStep(4);
                navigate(-1, { replace: true });
              }}
              error={t("errors.payment-card-required", { type: "Apple" })}
              isServerError={false}
            />
          ),
          closeOnEscape: false,
          closeOnClickOutside: false,
        });
        return;
      }
      paymentRequest.show();
    }
  };

  const handleChangePaymentMethod = () => {
    setServiceStep(4);
    navigate(-1, { replace: true });
  };

  const PayIcon = useMemo(() => {
    if (type === "google") {
      return () => (
        <img
          src={GoogleIcon}
          alt="Google Pay icon"
          style={{ width: 36, height: 36 }}
        />
      );
    }

    return () => (
      <img
        src={AppleIcon}
        alt="Apple Pay icon"
        style={{ width: 36, height: 36 }}
      />
    );
  }, [type]);

  if (!stripe || !paymentRequest || !Object.keys(canMakePayment).length) {
    return (
      <div className="ticket-view-loading">
        <Loader />
      </div>
    );
  }

  return (
    <div className="ticket-services">
      <div className="wrapper">
        {/* <div className="page-hdr">
          <button className="back-btn" onClick={handleChangePaymentMethod}>
            <ChevronsIconLeft />
            <div className="lbl">{t("back")}</div>
          </button>
          <div className="title">{t("review-and-pay")}</div>
        </div> */}
        <div className="content">
          <div className="section">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: rem(16),
                paddingTop: rem(16),
              }}
            >
              <button
                className="back-btn"
                style={{
                  marginRight: rem(10),
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => navigate(-1)}
              >
                <ChevronsIconLeft w={rem(22)} h={rem(22)} color="#F64E60" />
              </button>
              <Text
                size={rem(18)}
                weight={450}
                color="#F64E60"
                style={{ display: "flex", alignItems: "center" }}
              >
                {t("review-and-pay")}
              </Text>
            </div>
            {/* <Text
              size={rem(18)}
              weight={450}
              pt={rem(14)}
              pl={rem(18)}
              color="#F64E60"
            >
              {t("review-and-pay")}
            </Text> */}
            <Divider my="sm" />
            <Flex direction="column">
              {reduxstate.currentDataFor === "service" ? (
                <>
                  <ServiceInfoCard
                    serviceData={reduxstate.serviceData}
                    resource={reduxstate.resourceDetail}
                    isReadMore={false}
                    selectedStep={5}
                    toggleReadMore={() => {}}
                    when={{
                      day: bookServiceBody.slot_date,
                      start: bookServiceBody.slot_start_time,
                      end: bookServiceBody.slot_end_time,
                    }}
                    where={reduxstate.serviceSelectedLocation}
                    slotData={reduxstate.slotData}
                  />
                  <UserDetails />
                  <ServiceBuyingSummary reduxstate={reduxstate} />
                </>
              ) : (
                <>
                  <EventInfoCard
                    reduxstate={reduxstate}
                    selectedStep={5}
                    isReadMore={false}
                    toggleReadMore={() => {}}
                  />
                  <UserDetails />
                  <EventBuyingSummary reduxstate={reduxstate} />
                </>
              )}
              <div className="section">
                <div className="block">
                  <Flex direction="row" justify="space-between" align="center">
                    <Flex direction="row" gap="sm" align="center">
                      <PayIcon />
                      <Text fw={700} fz="lg">
                        {type === "google" ? t("google-pay") : t("apple-pay")}
                      </Text>
                    </Flex>
                    <Button
                      variant="subtle"
                      onClick={handleChangePaymentMethod}
                      fz="md"
                      c="#584CF4"
                    >
                      {t("change")}
                    </Button>
                  </Flex>
                </div>
              </div>
              <Button
                fullWidth
                radius={10}
                bg="#f64e60"
                size="lg"
                fz="md"
                onClick={handleOpenSheet}
                sx={{
                  "&:hover": {
                    backgroundColor: "#f64e60ee",
                    transition: "background-color 0.2s ease-in-out",
                  },
                }}
              >
                {t("pay-and-confirm")}
              </Button>
            </Flex>
          </div>
        </div>
      </div>
    </div>
  );
}
