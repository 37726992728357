import React, { useEffect } from "react";
import moment from "moment";
import { Button, Divider, Flex, Image, Text, rem } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

import NEW_BOOKING_URL from "utils/getNewBookingURL";

import { TimerIcon, CheckIcon, LocationIcon } from "assets/icon";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import { LANGUAGE_SELECTED } from "constants";

function BookingServiceConfirmation({
  bookingConfirmationData,
  serviceData,
  where,
}) {
  const {
    paymentStatus,
    slot_date,
    slot_start_time,
    slot_end_time,
    amount,
    servicesBought,
    totalPaymentServiceFee,
  } = bookingConfirmationData;
  const resourceDetail =
    "resource_detail" in bookingConfirmationData
      ? bookingConfirmationData.resource_detail
      : bookingConfirmationData.resourceDetail;
  const id = window.localStorage.getItem("ticksID") || "";
  const email = window.localStorage.getItem("@user-email");

  const { classes } = useStyles();
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 425px)");

  return (
    <div className="ticket-booking order-confirmation">
      <div className="wrapper">
        <div className="page-hdr">
          {/* <div className="title">{t("order-confirmation")}</div> */}
        </div>
        <div className="content">
          {/* Ticket Info*/}
          <div className="section">
            <Text
              size={rem(18)}
              weight={450}
              pt={rem(14)}
              pl={rem(18)}
              color="#F64E60"
            >
              {t("order-confirmation")}
            </Text>
            <Divider my="sm" />
            <div className="block info">
              <Flex direction="row" gap="md">
                <Image
                  src={serviceData?.servicePhoto}
                  height={rem(70)}
                  width={rem(110)}
                  radius="md"
                />
                <Flex direction="column" gap={rem(4)}>
                  <Text
                    size={isMobile ? "sm" : "md"}
                    weight={600}
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {serviceData?.serviceName}
                  </Text>
                  <Flex direction="column">
                    <Text fz="sm" color="#880088">
                      {/* {serviceData.description} */}
                      {/* {Description} */}
                    </Text>
                    <Flex direction="row" gap={rem(6)}>
                      <Text fz="sm" color="dark.3">
                        {bookingConfirmationData?.duration} mins
                      </Text>
                      <Text fz="sm" color="dark.3">
                        -
                      </Text>
                      <Text fz="sm" color="dark.3">
                        €{bookingConfirmationData?.servicesBought?.price}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>

              <Flex mt="xl" direction="column">
                <div className="item">
                  <Image
                    src={
                      resourceDetail?.profilePic &&
                      resourceDetail?.profilePic.length > 0
                        ? resourceDetail?.profilePic
                        : `https://avatar.iran.liara.run/username?username=${resourceDetail?.fullName
                            ?.split(" ")
                            ?.join("+")}`
                    }
                    height={rem(40)}
                    width={rem(40)}
                    radius={rem(12)}
                    mr={rem(12)}
                  />
                  <div className="meta">
                    <div className="lbl">{t("host")}</div>
                    <div className="value">{resourceDetail?.fullName}</div>
                  </div>
                </div>
                <div className="item">
                  <div className="icon">
                    <LocationIcon />
                  </div>
                  <div className="meta">
                    <div className="lbl">{t("where")}</div>
                    <div className="value">{where?.venueName}</div>
                    <div className="text">{where?.address?.address}</div>
                  </div>
                </div>
                <div className="item">
                  <div className="icon">
                    <TimerIcon />
                  </div>
                  <div className="meta">
                    <div className="lbl">{t("when")}</div>
                    <div className="value">
                      {moment(slot_date).format("dddd, MMMM D")} from{" "}
                      {slot_start_time} - {slot_end_time}
                    </div>
                  </div>
                </div>
              </Flex>
            </div>
          </div>
          <div className="section payment-due">
            <div>
              {amount === 0 ? (
                <div className="title-free">{t("Free-service")}</div>
              ) : paymentStatus === "Unpaid" ? (
                <div className="title">{t("payment-due")}</div>
              ) : (
                <div className="title">
                  {t("payment-confirmed")}
                  <div className="icon">
                    <CheckIcon />
                  </div>
                </div>
              )}
            </div>

            <div className="ticks">
              <div className="item">
                <div className="lit">
                  <div className="lbl">{serviceData?.serviceName}</div>
                  {/* <div className="price">{`€${servicesBought?.price}`}</div> */}
                  {/* <Text c="dimmed" fz="xs">{`€${totalPaymentServiceFee} processing fee`}</Text> */}
                </div>
                <div className="rit">
                  <div className="qty"></div>
                  <div className="price">
                    €
                    {Number(servicesBought?.price) +
                      (!!servicesBought?.absorbServiceFee
                        ? 0
                        : Number(servicesBought?.serviceFee))}
                  </div>
                </div>
              </div>
              <Flex justify="space-between" align="center" direction="row">
                <Text fz={rem(15)} fw="600">
                  {t("processing-fee")}
                </Text>
                <Text fz={rem(15)} fw="600">
                  €{totalPaymentServiceFee}
                </Text>
              </Flex>
            </div>

            <div className="total">
              <div className="text">
                {t("total-line", {
                  processingFee: (Number(totalPaymentServiceFee) || 0)?.toFixed(
                    2
                  ),
                })}
              </div>
              <div className="price">€{amount?.toFixed(2)}</div>
            </div>
            {paymentStatus === "Unpaid" && (
              <div className="notice" style={{ color: "gray" }}>
                {t("host-may-inquire")}
              </div>
            )}
          </div>
          {/* <div
            className="section succcess-blk"
            style={{
              display: "grid",
              placeItems: "center",
              gap: 12,
              paddingTop: "22px",
              paddingBottom: "22px",
            }}
          > */}
          <div
            className="section success-blk"
            style={{
              display: "grid",
              placeItems: "center",
              // gap: 12,
              // paddingTop: "22px",
              // paddingBottom: "22px",
            }}
          >
            <img src="/images/success.svg" alt="success" className="vector" />
            <div className="label">{t("order-confirmed")}</div>
            <div className="text">{t("booking-details-sent-to")}</div>
            <div className="address">{email}</div>
          </div>
          {/* </div> */}
          <div className="block section">
            <Button
              className={classes.submit}
              variant="filled"
              fullWidth
              // onClick={handleRestart}
              component="a"
              href={`${NEW_BOOKING_URL}/${id}`}
              target="_blank"
              radius={10}
            >
              {t("book-again")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookingServiceConfirmation;
